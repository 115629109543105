const formatBytes = (bytes: number, decimals: number = 2, base: number = 1024): string =>
{
    if (bytes === 0) return '0 Bytes';
    
    const units: string[] = base === 1024 ?
        ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
        ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const k: number = base;
    const dm: number = decimals < 0 ? 0 : decimals;
    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i];
};

export {
    formatBytes
};