import './index.scss';

import CountUp from 'react-countup';

import {
    ReactElement,
    useEffect,
    useState
} from 'react';

import {
    socketGet,
    socketSend
} from '../../sockets';

import {
    TServerInfo,
    TPingBackData,
    TPayloadDiskData
} from '../../types';

import {
    InternalSocketLatencyDecimals
} from '../../config';

/** Timer and interval objects */
const timers: {
    onMount?: NodeJS.Timeout,
    latencyInitiator?: NodeJS.Timeout,
    latencyChecker?: NodeJS.Timeout,
    latencyTimeout?: NodeJS.Timeout
} = {};

/** How often we're checking for latency */
const latencyInterval: number = 5000;

/** Storage for any previous latency calculations */
const latencyMeasurements: number[] = [];

/** Server info cache */
const ServerInfo: TServerInfo = {
    pings: {
        current: [null, null],
        previous: [null, null]
    },
    nfsConnected: false,
    upstreamConnected: false
};

const ServerStatus = ({
    onEstablished,
    onInterrupt = null,
    onPingBack = null,
    countDelay = null
}: {
    onEstablished: (socket: WebSocket, data: any) => void;
    onInterrupt?: (state?: number, origin?: string) => void;
    onPingBack?: (data: TPingBackData) => void;
    countDelay?: boolean;
}): ReactElement =>
{
    /** Latency states */
    let [clientLatency, updateLatency] = useState({
        current: 0,
        previous: 0
    });

    /** Server states */
    let [serverStates, setServerStates] = useState<TServerInfo>({
        pings: {
            current: [null, null],
            previous: [null, null]
        },
        nfsConnected: false,
        upstreamConnected: false
    });

    /** Mean state */
    let [currentMean, updateMean] = useState<number>(null);

    /** Count delay state */
    let [countDelayState, setCountDelayState] = useState<boolean>(countDelay ? true : false);
    useEffect(() => setCountDelayState(countDelay), [countDelay]);

    /** Handle the server status payload */
    const HandleServerInfo = (data: TPingBackData) =>
    {
        const { currentPings, diskData } = data.serverStatus;

        /** Set to-be previous values */
        ServerInfo.pings.previous = ServerInfo.pings.current;
        /** Set current values */
        ServerInfo.pings.current = Object.values(currentPings).map(Number).slice(0, 2) as [number, number];

        setServerStates({
            ...ServerInfo,
            nfsConnected: diskData.nfsConnected || false,
            upstreamConnected: diskData.upstreamConnected || false
        });

        /** Calculate latency between the [ws/server <-> client] */
        const latency = (Date.now()) - data.timestamp;

        /** Update latency state */
        updateLatency({
            current: latency,
            previous: latencyMeasurements[
                latencyMeasurements.length - 1
            ] || 0
        });

        /** Store the 10 most recent latency calculations */
        latencyMeasurements.push(latency);

        /** Keep this array at 10 in length */
        if(latencyMeasurements.length > 10) latencyMeasurements.shift();
    };

    const callLatencyUpdate = (): Promise<boolean> =>
    {
        clearTimeout(timers.latencyTimeout);

        return new Promise((resolve, reject) =>
        {
            /**
             * Set timeout to remove listener if no response
             * This should mean that the server is unresponsive
            */
            timers.latencyTimeout = setTimeout(() =>
            {
                /** Call breakdown function if set */
                if(onInterrupt) onInterrupt(1, 'Latency check timed out.');

                /** Reject promise */
                reject(new Error('Socket response timed out.'));
            }, (latencyInterval - 500));

            socketGet(onEstablished).then((socketConnection: WebSocket) =>
            {
                if(socketConnection && socketConnection.readyState === WebSocket.OPEN)
                {
                    const messageListener = (event: MessageEvent) =>
                    {
                        Promise.resolve(JSON.parse(event.data)).then((data: any) =>
                        {
                            if(data.type === 'pingBack')
                            {
                                /** Clean up listener */
                                socketConnection.removeEventListener('message', messageListener);
                                /** Handle server status data */
                                HandleServerInfo(data);
                                /** Clear timeout */
                                clearTimeout(timers.latencyTimeout);
                                /** Callback */
                                if(onPingBack) onPingBack(data);
                                
                                resolve(true);
                            }
                        }).catch((error) =>
                        {
                            reject(new Error(`Socket latency pinging failed: ${error}`));
                        });
                    };

                    /** Clean up any old listener and add new */
                    socketConnection.removeEventListener('message', messageListener);
                    socketConnection.addEventListener('message', messageListener);
                    /** Send ping */
                    socketSend({ type: 'ping' }, socketConnection);
                }
            }).catch((error) =>
            {
                reject(new Error(`Socket latency ping failed: ${error}`));
            });
        });
    };

    useEffect(() =>
    {
        clearTimeout(timers.onMount);

        timers.onMount = setTimeout(() =>
        {
            callLatencyUpdate().then(() =>
            {
                if(!timers.latencyChecker)
                {
                    clearTimeout(timers.latencyInitiator);
                    timers.latencyInitiator = setTimeout(() =>
                    {
                        /** Initiate the latency interval */
                        timers.latencyChecker = setInterval(() =>
                        {
                            callLatencyUpdate().catch((error) =>
                            {
                                /** Set interrupted state */
                                onInterrupt(0, 'Latency check errored out.');
                                /** Clean up timeouts */
                                clearTimeout(timers.latencyTimeout);
                                clearInterval(timers.latencyChecker);
                                /** Log the error */
                                console.error(error);
                            });
                        }, latencyInterval);
                    }, latencyInterval);
                }
            }).catch((error) =>
            {
                /** Clean up timeouts */
                clearTimeout(timers.latencyTimeout);
                /** Set interrupted state */
                onInterrupt(0, 'Initial latency check errored.');
                /** Log the error */
                console.error(error);
            });
        }, 100);
    }, []);

    useEffect(() =>
    {
        if(latencyMeasurements.length >= 1)
        {
            updateMean(Math.round(latencyMeasurements.reduce(
                (a, b) => a + b
            ) / latencyMeasurements.length));
        }
    }, [clientLatency]);

    return (
        <div className="container">
            <div className="ServerStatus">
                <span className="latencyServer">
                    Latency: <span>{clientLatency.current ? (!countDelayState ? <CountUp {...{
                        start: clientLatency.previous || 0,
                        end: clientLatency.current || 0,
                        suffix: ' ms'
                    }}
                        /> : <span>0 ms</span>) : '-'}</span>
                </span>

                <span className="latencyMean">{`Mean: ~ ${currentMean} ms`}</span>
                <span className="internalHeader">Internal servers:</span>

                <div className="internalPings">
                    {Array(3).fill(null).map((_, index) =>
                    {
                        let i = (index > 1 ? index - 1 : index);

                        /**
                         * We're measuringen on the websocket server, thus we are only able to
                         * calculate the latency between the other servers and the websocket server, so
                         * the host is displayed in the middle (where index equals 1).
                         * 
                         * In theory, we could fetch the latency to the websocket server from the two
                         * other servers, and then calculate the mean and transfer that value back to
                         * the websocket server for display, but that would be a bit overkill for now.
                         */
                        return index === 1 ? <span key={index}>{'[Host]'}</span> : (
                            (!countDelayState ? <CountUp {...{
                                key: index,
                                decimals: InternalSocketLatencyDecimals,
                                start: serverStates.pings.previous[i] || 0,
                                end: serverStates.pings.current[i] || 0,
                                suffix: ' ms'
                            }} /> : <span key={index}>{
                                `0.${Array(InternalSocketLatencyDecimals).fill('0').join('')} ms`
                            }</span>)
                        );
                    })}
                </div>

                <div className="serverConnections">
                    {[['upstreamConnected', 'Upstream (S3)'], ['nfsConnected', 'NFS']].map(([key, label], index) =>
                        {
                            const isConnected = serverStates[key];
                            
                            return (
                                <div key={index}>{label}: <span className={
                                    (isConnected ? 'isConnected' : 'isDisconnected')
                                }>
                                        {isConnected ? 'Connected!' : 'Down!'}
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export {
    ServerStatus
};