/** Months used for `date.getMonth()` */
const formatMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

/** Accepted format characters */
const formatChars = {
    'Y': (date: Date) => date.getFullYear(),
    'm': (date: Date) => formatMonths[date.getMonth()],
    'd': (date: Date) => date.getDate(),
    'H': (date: Date) => padZero(date.getHours()),
    'M': (date: Date) => padZero(date.getMinutes()),
    'S': (date: Date) => padZero(date.getSeconds()),
};

const padZero = (num: number): string =>
{
    return num < 10 ? `0${num}` : `${num}`;
}

/**
 * Formats a unix timestamp to readable format
 * 
 * @param format 
 * @param timestamp 
 */
const dateFormat = (format: string, timestamp: number): string =>
{
    const date = new Date(timestamp);

    return format.split('').map(
		(char) => formatChars[char] ? formatChars[char](date) : char
	).join('');
};

export {
    dateFormat
};
