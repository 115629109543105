import {
    webSocketUrl
} from '../config';

/** Socket timeout amount */
const socketEstablishTimeout = 7500;

/** Active socket connection */
let socketConnection: WebSocket = null;

/** Active connection UUID */
let socketUUID: string = null;

/**
 * Returns the socket UUID.
 */
const socketGetUUID = (): string =>
{
    return socketUUID;
};

/**
 * Waits for the socket to become ready.
 */
const socketAwaitReady = async (socket: WebSocket, timeout: number = socketEstablishTimeout) =>
{
    const isOpened = socket.readyState === WebSocket.OPEN;
    
    if(socket.readyState === WebSocket.CONNECTING)
    {
        const intraSleep = 100;
        const timeToLive = timeout / intraSleep;

        for(let loop = 0; loop < timeToLive; loop++)
        {
            if(socket.readyState !== WebSocket.CONNECTING)
            {
                break;
            }

            await new Promise(resolve => setTimeout(resolve, intraSleep));
        }
    }

    return isOpened;
};


/**
 * Returns the socket connection.
 */
const socketGet = (onInitialEstablish?: (socket: WebSocket, data: any) => void): Promise<WebSocket | null> =>
{
    return new Promise((resolve, reject) =>
    {
        /** Check if we already have an open socket running */
        if(socketConnection && socketConnection.readyState === WebSocket.OPEN)
        {
            /** Re-use the existing socket */
            resolve(socketConnection);
        } else {
            console.log('No pre-existing socket connection, establishing a new one.');

            /** Reset UUID */
            socketUUID = null;
            
            /** Attempt to establish a new socket */
            socketEstablish(onInitialEstablish).then((socketConnection: WebSocket) =>
            {
                resolve(socketConnection);
            }).catch((error: Error) =>
            {
                reject(error);
            });
        }
    });
};

/**
 * Establishes a socket connection to the server.
 */
const socketEstablish = (onInitialEstablish?: (socket: WebSocket, data: any) => void): Promise<WebSocket | Error> =>
{
    return new Promise((resolve, reject) =>
    {
        /** Socket timeout */
        let openTimeout: NodeJS.Timeout = null;

        /** Establish the socket connection */
        socketConnection = new WebSocket(webSocketUrl);

        const complete = (handler: any, response: WebSocket | Error, closeSocket?: boolean) =>
        {
            /** Clean up timeout */
            clearTimeout(openTimeout);

            if(closeSocket)
            {
                socketConnection.close();
            }

            /** Complete the promise */
            handler(response);
        };

        const openEventListener = () =>
        {
            if (!socketAwaitReady(socketConnection))
            {
                complete(reject, new Error('Failed to await socket connection.'), true);
            }

            if(!socketUUID)
            {
                const establishListener = (event: MessageEvent) =>
                {
                    Promise.resolve(JSON.parse(event.data)).then((data: any) =>
                    {
                        if(data.type === 'established' && data.uuid)
                        {
                            socketUUID = data.uuid;
    
                            /** Initial establish callback */
                            if(onInitialEstablish)
                                onInitialEstablish(socketConnection, data);
    
                            /** Clean up listener and resolve promise */
                            socketConnection.removeEventListener('message', establishListener);
                            complete(resolve, socketConnection);
                        } else {
                            complete(reject, new Error('Failed to get initial UUID from socket server.'), true);
                        }
                    }).catch(() =>
                    {
                        complete(reject, new Error('Failed to read socket response.'));
                    });
                };
    
                socketConnection.addEventListener('message', establishListener);
                socketEstablishUuid(socketConnection);
            } else {
                complete(resolve, socketConnection);
            }
        };

        const errorEventListener = () =>
        {
            complete(reject, new Error('Socket connection failed to establish.'), true);
        };

        socketConnection.addEventListener('open', openEventListener);

        openTimeout = setTimeout(() =>
        {
            complete(reject, new Error('Socket connection failed to establish.'), true);
        }, socketEstablishTimeout);

        socketConnection.addEventListener('error', errorEventListener);
    });
};

/**
 * Sends a UUID to the server.
 */
const socketEstablishUuid = async (socket: WebSocket | null = null) =>
{
    let connection = socket ? socket : await socketGet();

    if(connection.readyState === WebSocket.OPEN)
    {
        connection.send(JSON.stringify({
            type: 'establish',
            timestamp: Date.now()
        }));
    }
};

/**
 * Sends data to the server.
 */
const socketSend = async (data: any, socket: WebSocket | null = null) =>
{
    let connection = socket
        ? socket
        : await socketGet();

    let uuid = socketGetUUID();

    if(!uuid)
    {
        return null;
    }

    if(connection.readyState === WebSocket.OPEN)
    {
        connection.send(JSON.stringify({
            uuid: uuid,
            timestamp: Date.now(),
            ...data
        }));
    }
};

export {
    socketEstablish,
    socketGetUUID,
    socketGet,
    socketSend,
    socketConnection
};
