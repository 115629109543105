import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './main';

ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement
).render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);