import './index.scss';

import {
    Fragment,
    ReactElement,
    useEffect,
    useState
} from 'react';

import {
    randomFromArray
} from '../../helpers';

/** Timer and interval objects */
const timers: {
    onMount?: NodeJS.Timeout
} = {};

/** Available page headers */
const pageHeader = randomFromArray([
    'Oops!',
    'Fuck!',
    'Uh oh ...'
]);

/** Socket is closed */
const StateClosed = () =>
{
    return (
        <Fragment>
            <h2>{pageHeader}</h2>
            <pre>
                <span>Unable to establish a socket connection.</span>
                <span>Please try again later.</span>
            </pre>
        </Fragment>
    );
};

/** Socket has timed out */
const StateTimeout = () =>
{
    return (
        <Fragment>
            <h2>{pageHeader}</h2>
            <pre>
                <span>The connection has expired.</span>
                <span>You can refresh the page to generate a new session.</span>
            </pre>
        </Fragment>
    );
};

const InterruptedPage = ({ state }: {
    state: number
}): ReactElement =>
{
    let [hasLoaded, setHasLoaded] = useState<boolean>(false);

    useEffect(() =>
    {
        clearTimeout(timers.onMount);
        timers.onMount = setTimeout(() => setHasLoaded(true), 100);
    }, []);

    return (
        <div className="interruptedContainer">
            <div className={hasLoaded ? 'visible' : null}>
                {state === 0 ? <StateClosed /> : <StateTimeout /> }
            </div>
        </div>
    );
};

export {
    InterruptedPage
};