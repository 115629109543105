/** Splash header */
const SplashHeaderText: string = 'FIVE.SH';

/** Decimals to use for internal server pings */
const InternalSocketLatencyDecimals = 3;

/** Base domain for the Indexer files */
const IndexerBaseDomain = 'https://five.sh/';

export {
    SplashHeaderText,
    InternalSocketLatencyDecimals,
    IndexerBaseDomain
};