const relativeFormatting = (timestamp: number) =>
{
    const currentTimestamp = new Date().getTime();
    const difference = timestamp - currentTimestamp;

    const dayInMs = 1000 * 60 * 60 * 24;
    const daysDifference = Math.round(difference / dayInMs);

    const rtf = new Intl.RelativeTimeFormat('en', {
        numeric: daysDifference > -7 ? 'auto' : 'always',
    });

    /** Returns a year formatted timestamp */
    if (daysDifference <= -365)
        return rtf.format(Math.ceil(daysDifference / 365), 'year');
    
    /** Returns a month formatted timestamp */
    if (daysDifference < -30.437)
        return rtf.format(Math.ceil(daysDifference / 30.437), 'month');

    /** Returns a week formatted timestamp */
    if (daysDifference <= -3 * 7)
        return rtf.format(Math.ceil(daysDifference / 7), 'week');

    /** Returns a day formatted timestamp */
    return rtf.format(daysDifference, 'day');
};

export {
    relativeFormatting
};
