import './main.scss';

import {
  ReactElement,
  Fragment,
  useEffect,
  useState,
  useRef
} from 'react';

import {
  IndexerStatus,
  ServerStatus,
  InterruptedPage,
  SplashView
} from './components/';

import {
  socketConnection
} from './sockets/';

import {
  TPingBackData,
  TPayloadDiskData,
  TServerEstablished,
  TIndexerBatchFile,
  TIndexerBatchData
} from './types';

/** Timer and interval objects */
const timers: {
  interruptTimer: NodeJS.Timeout
} = {
  interruptTimer: null
};

const Main = (): ReactElement =>
{
  /** Ready state */
  let [isReady, setReady] = useState<boolean>(false);

  /**
   * Interrupted state:
   * 
   * -1: No interruption
   *  0: Socket error (closed)
   *  1: Socket timeout
   */
  let [interruptedState, setInterruptedState] = useState<number>(-1);

  /** Splash state */
  let [splashState, setSplashState] = useState<boolean>(true);

  /** Established states */
  let [establishedData, setEstablishedData] = useState<{
    socket: WebSocket,
    data: any
  }>(null);

  /** Pingback data */
  let [pingBackData, setPingBackData] = useState<TPayloadDiskData>(null);

  /** References */
  const splashRef = useRef<HTMLDivElement>(null);

  /**
   * Fired upon a newly established socket connection
   */
  const onSocketEstablished = (socket: WebSocket, data: TServerEstablished) =>
  {
    const { recentFiles } = data.payloadInitial;
    /** Set established data */
    setEstablishedData({ socket, data });
    /** Set ready state */
    setReady(true);
  };

  useEffect(() =>
  {
    if(isReady || interruptedState > -1)
    {
      setTimeout(() =>
      {
        if(splashRef.current)
        {
          splashRef.current.classList.add('invalidate');
          splashRef.current.style.opacity = '0';
        }
      }, 1500);
  
      setTimeout(() => setSplashState(false), 2500);
    }
  }, [isReady, interruptedState]);

  /** On interrupted callback */
  const onInterrupt = (state: number, reason?: string) =>
  {
    if(timers.interruptTimer === null && interruptedState === -1)
    {
      timers.interruptTimer = setTimeout(() =>
      {
        /** Set interrupted state */
        setInterruptedState(state);
        /** Write reasoning to console */
        console.log(`Interrupted! reason: ${reason || 'unknown'}`, new Date());
        /** Close connection if set */
        if(socketConnection) socketConnection.close();
      }, 500);
    }
  };

  /** On pingback callback */
  const onPingBack = (data: TPingBackData) =>
  {
    if(data.serverStatus && data.serverStatus.diskData)
    {
      setPingBackData(data.serverStatus.diskData);
    }
  };

  return (
    <Fragment>
      {interruptedState > -1 ? <InterruptedPage state={interruptedState} /> : (
        <div className="pageContent">

        {splashState ? <SplashView ref={splashRef} /> : null}

        <div className="container">
          <div className="headerMain">
            <h1>STATUS</h1>
            <h2>FIVE.SH</h2>
          </div>

          <ServerStatus {...{
            countDelay: splashState,
            onEstablished: onSocketEstablished,
            onPingBack: onPingBack,
            onInterrupt: onInterrupt
          }}/>

          {establishedData ? <IndexerStatus {...{
            payload: establishedData.data,
            pingBackData: pingBackData
          }} /> : null}
        </div>
      </div>
      )}
    </Fragment>
  );
};

export default Main;
