import './index.scss';

import {
    forwardRef,
    useEffect,
    useState
} from 'react';

import {
    SplashHeaderText
} from '../../config';

import { HeartbeatSplash } from './heartbeatSplash';

const SplashView = forwardRef<HTMLDivElement>((props, ref) =>
{
    /** Visible states */
    let [isVisible, setVisible] = useState<boolean>(false);

    useEffect(() =>
    {
        setTimeout(() => setVisible(true), 100);
    }, []);

    return (
        <div ref={ref} className="splashView">
            <div className={isVisible ? 'visible' : ''}>
                <h2>{SplashHeaderText}</h2>
                <span>Establishing connection ...</span>
                <HeartbeatSplash />
                <div className="versionLabel">{
                    process.env.REACT_APP_VERSION
                }</div>
            </div>
        </div>
    );
});

export {
    SplashView
};