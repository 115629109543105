const joinPath = (...paths: string[]): string =>
{
    return paths.reduce((prevPath, currentPath) =>
    {
        const cleanPrevPath = prevPath.replace(/\/+$/g, '');
        const cleanCurrentPath = currentPath.replace(/^\/+/g, '');
        return `${cleanPrevPath}/${cleanCurrentPath}`;
    });
};

export {
    joinPath
};