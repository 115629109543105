import './index.scss';

import {
    BatchOverview
} from './batchOverview';

import {
    ReactElement,
    useEffect,
    useState
} from 'react';

import {
    TIndexerStatus,
    TPayloadDiskData
} from '../../types';

import {
    formatBytes
} from '../../helpers';

const InnerIndexerHeader = ({ diskData }: { diskData: TPayloadDiskData }): ReactElement =>
{
    /** Format total files */
    const totalFiles = diskData.totalStoredFiles.toLocaleString('en-US').replace(/,/g, ' ');
    /** Format total storage */
    const totalStorage = formatBytes(diskData.totalStorageUsed, 1);

    return (
        <div className="header">Files
            <span>{`:: Total stored: ${totalFiles} files (${totalStorage})`}</span>
        </div>
    );
};

const InnerIndexerContainer = ({ payload, pingBackData }: TIndexerStatus): ReactElement =>
{
    /** Loaded state */
    let [hasLoaded, setHasLoaded] = useState<boolean>(true);
    /** Payload state */
    let [currentPayload, setPayload] = useState<any>(null);

    useEffect(() =>
    {
        /** Get initial payload data from the socket response */
        const { payloadInitial } = payload;
        /** Set payload */
        setPayload(payloadInitial);
        /** Set loaded state */
        setHasLoaded(true);
    }, [payload]);

    return hasLoaded && currentPayload ? (
        <div className="inner">
            <InnerIndexerHeader diskData={pingBackData} />

            <div className="batchHeader">
                <span>Recently uploaded batches {`(${Object.keys(currentPayload.recentFiles).length})`}:</span>
            </div>

            <BatchOverview {...{
                payload: currentPayload.recentFiles
            }} />
        </div>
    ) : null;
};

const IndexerStatus = ({
    payload,
    pingBackData
}: TIndexerStatus): ReactElement =>
{
    /** Loaded state */
    let [hasLoaded, setHasLoaded] = useState<boolean>(false);
    /** Update loaded state on payload retrieval */
    useEffect(() => setHasLoaded(payload), [payload]);

    return (
        <div className="container">
            <div className="indexerStatus">
                {(hasLoaded && pingBackData) ? <InnerIndexerContainer {...{
                    payload, pingBackData
                }} /> : null}
            </div>
        </div>
    );
};

export {
    IndexerStatus
};