/** Web socket protocol */
const webSocketProtocol: string = 'wss';
/** Web socket host */
const webSocketHost: string = 'status.five.sh';
/** Web socket uri */
const webSocketUri: string = '/ws';
/** Web socket url (full uri) */
const webSocketUrl: string = `${webSocketProtocol}://${webSocketHost}${webSocketUri}`;

export {
    webSocketProtocol,
    webSocketHost,
    webSocketUri,
    webSocketUrl
};