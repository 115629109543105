import './index.scss';

import {
    UIEvent,
    useRef,
    useEffect,
    useState,
    ReactElement
} from 'react';

type TScrollableContentProps = {
    /** Any additional classes applied to the container */
    defaultClasses?: Array<string>;
    /** Height of the container */
    height?: number;
    /** onMount hook */
    onMount?: (ref?: HTMLDivElement) => void;
    /** onClick hook */
    onClick?: (e?: UIEvent) => void;
    /** Children */
    children: ReactElement | Array<ReactElement>;
};

const ScrollableContent = ({
    defaultClasses = [],
    onMount = null,
    onClick = null,
    height = 175,
    children
}: TScrollableContentProps): ReactElement =>
{
    /** References */
    const scrollableRef = useRef<HTMLDivElement>(null);
    
    /** Classes state */
    let [classes, setClasses] = useState<Array<string>>(defaultClasses);
    /** Scrolling states */
    let [scrollState, setScrollState] = useState<any>({
        topVisible: true,
        bottomVisible: false
    });

    /** Update classes on scroll */
    useEffect(() =>
    {
        let newClasses: Array<string> = [...defaultClasses];

        for(const [key, value] of Object.entries({
            topVisible: 'fadeTop',
            bottomVisible: 'fadeBottom'
        })) {
            if(!scrollState[key])  newClasses.push(value);
        }
        
        setClasses(newClasses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollState]);

    /** `onScroll` event handler */
    const onScroll = (): void =>
    {
        const target = scrollableRef.current;
        const visibleHeight = target.clientHeight;
        /** Update scrolling states */
        setScrollState({
            topVisible: target.scrollTop < 10,
            bottomVisible: (target.scrollTop + visibleHeight) > (target.scrollHeight - 10)
        });
    };

    /** Trigger an initial scroll event on children changes */
    useEffect(() => onScroll(), [children]);

    /** Trigger potential mount event(s) */
    useEffect(() =>
    {
        if(scrollableRef.current)
        {
            /** Trigger scroll event */
            onScroll();
            /** Trigger mount callback */
            if(onMount) onMount(scrollableRef.current);
        }
    }, [scrollableRef]);

    return (
        <div className={[...classes, 'scrollableContent'].join(' ')} ref={scrollableRef} onScroll={onScroll} style={{
            maxHeight: `${height}px`
        }}>
            <div className="wrapper" onClick={onClick}>
                {children}
            </div>
        </div>
    );
};

export {
    ScrollableContent
};