import {
    UIEvent,
    RefObject,
    ReactElement,
    Fragment,
    useRef,
    useEffect,
    useState
} from 'react';

import {
    dateFormat,
    formatBytes,
    relativeFormatting,
    joinPath
} from '../../../helpers';

import {
    IndexerBaseDomain
} from '../../../config';

import {
    ScrollableContent
} from '../../../components';

import {
    TIndexerStatus,
    TBatchContentFileItem,
    TIndexerBatchData,
    TIndexerBatchFile
} from '../../../types';

const useScrollIntoView = <T extends HTMLElement>(ref: RefObject<T>, containerClass: string) =>
{
    useEffect(() =>
    {
        if(!ref.current) return;

        const container = ref.current.closest(`.${containerClass}`);

        if(!container) return;

        const elementRect = ref.current.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const fullyVisible =
            elementRect.top >= containerRect.top &&
            elementRect.bottom <= containerRect.bottom;

        if(!fullyVisible)
        {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [ref, containerClass]);
};

const ContentFileExtended = ({ data, parent }: { data: TIndexerBatchFile, parent: RefObject<HTMLDivElement> }) =>
{
    const filehref = joinPath(IndexerBaseDomain, data.pathPublic);

    useScrollIntoView(parent, 'batchContent');

    return (
        <div className="fileDetails">
            <div className="fileSize">{formatBytes(data.fileSize)}</div>
            <div className="fileModified">{relativeFormatting(new Date(data.fileModified).valueOf())}</div>
            <div className="anchorWrapper">
                <a target="_blank" href={filehref}>URL:/{data.pathPublic}</a>
            </div>
        </div>
    );
}

type TContentFile = {
    item: TBatchContentFileItem;
    index?: number;
    extended?: TIndexerBatchFile;
    totalItems: number;
};

const ContentFile = ({ item, index = null, extended = null, totalItems }: TContentFile): ReactElement =>
{
    /** References */
    let contentReference = useRef<HTMLDivElement>(null);
    /** Extended state */
    let [currentExtended, setExtended] = useState<TIndexerBatchFile>(null);
    useEffect(() => setExtended(extended), [extended]);
    /** Get filename */
    const fileName = item.pathPublic.split('/').pop();

    return (
        <div ref={contentReference} className={`batchFileItem${
                (index === 0 || index === totalItems - 1) ? ' edged' : ''}`
            } data-index={index}>
            <div className="fileName" title={fileName}>
                {`${index + 1}/${totalItems}: ${fileName}`}
            </div>

            {currentExtended ? <ContentFileExtended {...{
                data: currentExtended,
                parent: contentReference || null
            }} /> : null}
        </div>
    );
};

const BatchSelected = ({ value, maxWidth }: { value: TIndexerBatchData, maxWidth: number }): ReactElement =>
{
    /** Item state */
    let [item, setItem] = useState<TIndexerBatchData>(null);
    /** Attributes state */
    let [currentAttributes, setAttributes] = useState<any>({});

    /** Attempt to update selected item */
    useEffect(() => {
        if(item !== value) {
            setSelectedFileIndex([null, null]);
        }
        setItem(value);
    }, [value]);

    /** Selected file state */
    let [selectedFileIndex, setSelectedFileIndex] = useState<[
        number, TIndexerBatchFile]
    >([null, null]);
    
    useEffect(() =>
    {
        setAttributes(maxWidth ? { style: { maxWidth: `${maxWidth}px`} } : null);
    }, [maxWidth]);

    return item ? (
        <div className="batchSelection" {...currentAttributes}>
            <div className="batchName">Batch: {item.batch}</div>
            <div className="batchInfo">Total files: {item.files.length}<span>+{item.totalSize}</span></div>
            <ScrollableContent defaultClasses={['batchContent']} {...{
                height: 250,
                /** Event delegation for clickable batch items */
                onClick: (e: UIEvent) =>
                {
                    let target = e.target as HTMLDivElement;

                    if(target.classList.contains('fileName'))
                        target = target.closest('.batchFileItem');
                    if(!target || !target.hasAttribute('data-index')) return;

                    const index = parseInt(target.getAttribute('data-index'));

                    setSelectedFileIndex(selectedFileIndex[0] === index
                        ? [null, null]
                        : [index, item.files[index]]
                    );
                }
            }}>
                {item.files.map((contentItem: TBatchContentFileItem, index: number) =>
                {
                    const [selectedIndex, selectedFileData] = selectedFileIndex;
                    const extended = selectedIndex === index ? selectedFileData : null;

                    return <ContentFile {...{
                        key: index,
                        item: contentItem,
                        index: index,
                        extended: extended,
                        totalItems: item.files.length
                    }} />
                })}
            </ScrollableContent>
        </div>
    ) : null;
};

const BatchItem = ({ item, isSelected, index }: {
    item: any,
    isSelected: boolean,
    index: number
}): ReactElement =>
{
    /** Batch timestamp state */
    let [itemTimestamp, setTimestamp] = useState<string>(null);
    /** Batch files state */
    let [itemFiles, setFiles] = useState<Array<any>>(null);
    
    useEffect(() =>
    {
        /** Extract values from item object */
        const { timestamp, files } = item;
        /** Set item files */
        setFiles(files);
        /** Set item (readable) timestamp */
        setTimestamp(dateFormat('m. d Y, H:M:S', parseInt(timestamp)));
    }, [item]);

    return (itemFiles && itemTimestamp) ? (
        <div
            className={'batchItem' + (isSelected ? ' selected' : '')}
            data-index={index}
        >
            <div>{itemTimestamp}</div>
            <div>+{itemFiles.length}</div>
        </div>
    ) : null;
}

const BatchOverview = ({
    payload
}: TIndexerStatus['payload']) =>
{
    /** Batch state */
    let [handledPayload, setPayload] = useState<any>(null);
    /** Container width state */
    let [containerWidth, setContainerWidth] = useState<number>(null);
    /** Selected batch state */
    let [selectedBatchIndex, setSelectedBatchIndex] = useState<number>(-1);
    let [selectedBatchData, setSelectedBatchData] = useState<any>(null);

    useEffect(() =>
    {
        if(selectedBatchIndex === null)
        {
            setSelectedBatchData(null);
        } else if(handledPayload  && handledPayload[selectedBatchIndex + 1])
        {
            setSelectedBatchData(handledPayload[selectedBatchIndex + 1]);
        }
    }, [handledPayload, selectedBatchIndex]);

    /** If we ever need to modify it any way, we can do that here */
    useEffect(() => setPayload(payload), [payload]);

    return (
        <Fragment>
            {handledPayload ? (
                <ScrollableContent defaultClasses={['batchContainer']} {...{
                    height: 140,
                    /** Event delegation for clickable batch items */
                    onClick: (e: UIEvent) =>
                    {
                        const target = e.target as HTMLDivElement;

                        if(target
                            && target.classList.contains('batchItem')
                            && target.hasAttribute('data-index'))
                        {
                            const index = parseInt(target.getAttribute('data-index'));
                            const newIndex = selectedBatchIndex === index ? -1 : index;
                            setSelectedBatchIndex(newIndex === -1 ? null : newIndex);
                        }
                    },
                    onMount: (element: HTMLDivElement) =>
                    {
                        if(element) setContainerWidth(element.offsetWidth);
                    }
                }}>
                    {Object.keys(handledPayload).map((key, index) =>
                    {
                        const itemKey = parseInt(key);
                        const item = payload[key];
                        const isSelected = (selectedBatchIndex > -1 && selectedBatchIndex === index);

                        return <BatchItem key={itemKey} isSelected={isSelected} item={item} index={index} />;
                    }).reverse()}
                </ScrollableContent>
            ) : null}

            {selectedBatchData && selectedBatchIndex > -1 ? <BatchSelected {...{
                value: selectedBatchData,
                maxWidth: containerWidth
            }} /> : null}
        </Fragment>
    )
};

export {
    BatchOverview
};